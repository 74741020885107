<template>
  <!-- Row -->

  <div>
  <b-row
    v-if="withCrossPrograms"
  >
    <b-col md="6">
      <b-form-group
        label="Program"
        label-for="blog-edit-program"
        class="mb-2"
      >
        <v-select
          :id="`CrossProgramSelection${conditionIndex}${conditionBlockIndex}${condition.id}`"
          v-model="condition.cross_program_id"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="programsList"
          :placeholder="'Select Programs'"
          :clearable="true"
          :multiple="false"
          :reduce="option => option.id"
          @input="setCrossProgramsFields"
        />
      </b-form-group>
    </b-col>
  </b-row>

  <b-row>
    <b-col
      cols="4"
    >
      <b-form-group
        label="Field Key"
      >
        <validation-provider
          #default="{ errors }"
          name="Field Key"
          rules="required"
          :vid="`Field Key${conditionIndex}${conditionBlockIndex}${condition.id}`"
        >
          <v-select
            v-model="condition.field_id"
            :options="fieldKeyValues"
            label="text"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Select a Field"
            @input="setOptions"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </b-col>
    <b-col
      cols="3"
    >
      <b-form-group
        label="Operator"
      >
        <validation-provider
          #default="{ errors }"
          name="Operator"
          rules="required"
          :vid="`Operator${conditionIndex}${conditionBlockIndex}${condition.id}`"
        >
          <v-select
            v-model="condition.operator"
            :options="operatorsOptions"
            :disabled="!condition.field_id"
            label="text"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :reduce="option => option.value"
            placeholder="Select an Operator"
            @input="setOperator"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-if="isDateOperator"
      cols="4"
    >
      <b-form-group
        label="Value"
      >
        <validation-provider
          #default="{ errors }"
          name="Value"
          rules="required"
          :vid="`ValueDatePicker${conditionIndex}${conditionBlockIndex}${condition.id}`"
        >
          <flat-pickr
            v-model="condition.value"
            class="form-control"
            :config="{ dateFormat: 'Y-m-d' }"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col
      v-else
      cols="4"
    >
      <b-form-group
        label="Value"
      >
        <template>
          <validation-provider
            #default="{ errors }"
            name="Value"
            :rules="{ required_if: { target: `Operator${conditionIndex}${conditionBlockIndex}${condition.id}`, values: operatorsWithValue } }"
            :vid="`Value${conditionIndex}${conditionBlockIndex}${condition.id}`"
          >
            <v-select
              v-if="valueOptions.length >= 1 && condition.operator !== '==contains' && !isOperatorWithoutValue"
              v-model="condition.value"
              placeholder="Select Value"
              :clearable="false"
              :options="valueOptions"
            />
            <v-select
              v-else-if="condition.operator == '==contains'"
              :ref="conditionIndex"
              v-model="condition.value"
              placeholder="Select Value"
              :clearable="false"
              :taggable="valueOptions.length == 0"
              :multiple="condition.operator === '==contains'"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="valueOptions"
              :class="{'is-invalid': errors.length > 0 }"
            >
              <template
                v-if="valueOptions.length == 0"
                #no-options
              >
                Type and press Enter to add value.
              </template>
            </v-select>
            <b-form-input
              v-else
              v-model="condition.value"
              :disabled="!condition.field_id || isOperatorWithoutValue"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </template>
      </b-form-group>
    </b-col>
    <b-col
      cols="1"
      align-self="center"
    >
      <feather-icon
        v-if="!hideDeleteRowIcon"
        icon="Trash2Icon"
        size="21"
        class="text-primary cursor-pointer"
        @click="removeConditionRow"
      />
    </b-col>
  </b-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required, requiredIf } from '@validations'
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'ConditionRow',
  components: {
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    ValidationProvider,
    flatPickr,
    vSelect,
  },
  props: {
    fieldsData: {
      type: Array,
      default: () => {},
    },
    condition: {
      type: Object,
      default: () => {},
    },
    conditionIndex: {
      type: Number,
      required: true,
    },
    conditionBlockIndex: {
      type: Number,
      required: true,
    },
    conditionBlockLength: {
      type: Number,
      required: true,
    },
    withCrossPrograms: {
      type: Boolean,
      default: () => false,
    },
    programsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueOptions: [],
      operators: [
        { value: '==', text: 'equals', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '!=', text: 'not equals', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '==contains', text: 'contains', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '!=contains', text: 'does not contain', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'dropdown', 'multi_select', 'checkbox', 'radio_button', 'group_selection'] },
        { value: '==empty', text: 'is empty', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dob', 'dropdown', 'multi_select', 'checkbox', 'radio_button'] },
        { value: '!=empty', text: 'is filled', appropriate: ['short_text', 'long_text', 'email', 'number', 'phone', 'date', 'dob', 'dropdown', 'multi_select', 'checkbox', 'radio_button'] },
        { value: '<', text: 'is less than', appropriate: ['number'] },
        { value: '>', text: 'is greater than', appropriate: ['number'] },
        { value: 'is_at', text: 'is at', appropriate: ['date', 'dob'] },
        { value: 'is_before', text: 'is before', appropriate: ['date', 'dob'] },
        { value: 'is_before_or_at', text: 'is before or at', appropriate: ['date', 'dob'] },
        { value: 'is_after', text: 'is after', appropriate: ['date', 'dob'] },
        { value: 'is_after_or_at', text: 'is after or at', appropriate: ['date', 'dob'] },
        { value: 'is_not_at', text: 'is not at', appropriate: ['date', 'dob'] },
        { value: 'is_yesterday', text: 'is yesterday', appropriate: ['date', 'dob'] },
        { value: 'is_today', text: 'is today', appropriate: ['date', 'dob'] },
        { value: 'is_this_week', text: 'is this week', appropriate: ['date', 'dob'] },
        { value: 'is_this_month', text: 'is this month', appropriate: ['date', 'dob'] },
        { value: 'is_this_year', text: 'is this year', appropriate: ['date', 'dob'] },
        { value: 'is_last_month', text: 'is last month', appropriate: ['date', 'dob'] },
        { value: 'is_next_month', text: 'is next month', appropriate: ['date', 'dob'] },
      ],
      operatorsOptions: [],
      crossProgramsFields: [],
      required,
      requiredIf,
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters['app-program/getIsLoading']
      },
      set(val) {
        this.$store.commit('app-program/SET_IS_LOADING', val)
      },
    },
    fieldKeyValues() {
      if (this.withCrossPrograms) {
        return this.crossProgramsFields
      }
      return this.fieldsData
    },
    hideDeleteRowIcon() {
      return this.conditionIndex === 0 && this.conditionBlockIndex === 0 && this.conditionBlockLength === 1
    },
    isDateOperator() {
      return ['is_at', 'is_before', 'is_before_or_at', 'is_after', 'is_after_or_at', 'is_not_at'].includes(this.condition.operator)
    },
    operatorsWithValue() {
      return [
        '==',
        '!=',
        '==contains',
        '!=contains',
        '<',
        '>',
        'is_at',
        'is_before',
        'is_before_or_at',
        'is_after',
        'is_after_or_at',
        'is_not_at',
      ]
    },
    operatorsWithoutValue() {
      return [
        '==empty',
        '!=empty',
        'is_yesterday',
        'is_today',
        'is_this_week',
        'is_this_month',
        'is_this_year',
        'is_last_month',
        'is_next_month',
      ]
    },
    isOperatorWithoutValue() {
      return this.operatorsWithoutValue.includes(this.condition.operator)
    },
  },
  async created() {
    this.isLoading = true

    if (this.condition.cross_program_id) {
      await this.setCrossProgramsFields()
    }

    this.setOptions(this.condition.field_id)
    this.isLoading = false
  },
  methods: {
    setOptions(fieldId) {
      const field = this.fieldKeyValues.find(item => item.value === fieldId)
      const intermediateResult = field?.meta

      if (intermediateResult && intermediateResult.length > 0) {
        this.valueOptions = JSON.parse(JSON.stringify(intermediateResult))
      } else {
        this.valueOptions = []
      }

      this.operatorsOptions = this.operators
        .filter(item => item.appropriate.includes(field?.type))
        .reduce((arr, item) => {
          arr.push({
            value: item.value,
            text: item.text,
          })
          return arr
        }, [])
    },
    removeConditionRow() {
      this.$emit('removeConditionRow')
    },
    setOperator() {
      if (this.condition.operator === '==contains' && this.condition.value === '' && this.valueOptions.length > 1) {
        this.condition.value = []
      } else if (this.isOperatorWithoutValue) {
        this.condition.value = null
      }
    },
    async setCrossProgramsFields() {
      if (!this.condition.cross_program_id) {
        this.crossProgramsFields = []
      }
      this.crossProgramsFields = await store.dispatch('app-program/prepareCrossProgramsFields', {
        programId: this.condition.cross_program_id,
        withoutFieldType: ['plain_text'],
      })
    },
  },
}
</script>

<style>

</style>
