var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-1"},[_c('h4',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" Condition "+_vm._s(_vm.conditionIndex + 1)+" "),_c('SquareIcon',{staticClass:"cursor-pointer",attrs:{"size":"19","icon":"Trash2Icon"},on:{"onIconClick":function($event){return _vm.removeItem(_vm.conditionsData.id)}}})],1),_c('scoped-conditions',{attrs:{"fields-data":_vm.ifConditionFieldsData,"scoped-conditions":_vm.conditionsData.scoped_conditions,"condition-id":_vm.conditionsData.id,"with-cross-programs":_vm.withCrossPrograms,"programs-list":_vm.programsList}}),_c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"modal-if-else"},[_vm._v("Then")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Action"}},[_c('validation-provider',{attrs:{"name":"Action","rules":"required","vid":("Action" + _vm.conditionIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.actionOptions,"label":"text","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select an action"},model:{value:(_vm.conditionsData.action),callback:function ($$v) {_vm.$set(_vm.conditionsData, "action", $$v)},expression:"conditionsData.action"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.stepsOptions.length)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Apply To"}},[_c('validation-provider',{attrs:{"name":"Apply to","rules":"required","vid":("Apply to" + _vm.conditionIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.applyTypes,"disabled":_vm.isDisabledSelection,"label":"text","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select an option"},on:{"input":function($event){_vm.conditionsData.related_id = null}},model:{value:(_vm.conditionsData.related_type),callback:function ($$v) {_vm.$set(_vm.conditionsData, "related_type", $$v)},expression:"conditionsData.related_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2128104821)})],1)],1):_vm._e(),(_vm.conditionsData.related_type === 'Field')?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Field Key"}},[_c('validation-provider',{attrs:{"name":"Field Key","rules":"required","vid":("Field Key" + _vm.conditionIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.childrenOptions,"label":"text","clearable":false,"disabled":_vm.isDisabledSelection,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select a Field"},model:{value:(_vm.conditionsData.related_id),callback:function ($$v) {_vm.$set(_vm.conditionsData, "related_id", $$v)},expression:"conditionsData.related_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4070706534)})],1)],1):_vm._e(),(_vm.conditionsData.related_type === 'Step')?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Step Key"}},[_c('validation-provider',{attrs:{"name":"Step Key","rules":"required","vid":("Step Key" + _vm.conditionIndex)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stepsOptions,"label":"text","clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (option) { return option.value; },"placeholder":"Select a step"},model:{value:(_vm.conditionsData.related_id),callback:function ($$v) {_vm.$set(_vm.conditionsData, "related_id", $$v)},expression:"conditionsData.related_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,370435848)})],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }